<template>
  <div
    class="embed-layout"
    style="max-width: 700px; margin: 0px auto; width: 100%"
  >
    <div class="relative">
      <LoadingComponent
        overlay
        overlayLoadingCenter
        v-if="isLoading"
        style="height: 100vh"
        class="d-flex align-center"
      />
      <v-card class="pa-0" style="box-shadow: none !important" v-else>
        <div class="d-flex justify-center pt-4">
          <v-img :src="botIcon" alt="bot Image" max-width="60" />
        </div>
        <div
          class="d-flex justify-center py-2 px-8 text-center"
          v-if="botDescription"
        >
          <p style="font-size: 13px" class="truncate-4">{{ botDescription }}</p>
        </div>

        <ChatComponent class="flex-grow-1" :activeBot="activeBot" />
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ChatComponent from "@/components/chat/Chatv2.vue";
import LoadingComponent from "@/components/helpers/Loading";

export default {
  name: "About",

  components: {
    ChatComponent,
    LoadingComponent,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  watch: {},

  mounted() {
    this.initEmbedBot();
  },

  methods: {
    ...mapActions("bots", ["getBots", "getBot"]),
    ...mapMutations("bots", ["setActiveBot", "setContact"]),

    initEmbedBot() {
      this.isLoading = true;
      this.getBot(this.embeddedBot).then((res) => {
        this.setActiveBot(res.data);
        this.setContact({
          emails: res.data?.emails,
          mobile_numbers: res.data?.mobile_numbers,
        });
        this.isLoading = false;
      });
    },
  },

  computed: {
    ...mapGetters("bots", ["bots", "activeBot"]),

    embeddedBot() {
      return this.$route.params.botId;
    },

    botDescription() {
      return this.activeBot?.description;
    },

    botIcon() {
      return this.activeBot?.icon;
    },

    dynamicStyles() {
      return {
        height: `100vh !important`, // Adjust the multiplier as needed
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-height {
  height: calc(100vh) !important;
  max-height: calc(100vh);
}
</style>
